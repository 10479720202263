import { date, hamburger } from '@doddle-agency/doddle-base';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

date();
hamburger();

// Add menu button for submenu
const addSubMenuBtn = document.querySelectorAll('.nav__item--parent');

for (let i = 0; i < addSubMenuBtn.length; i++) {
  addSubMenuBtn[i].innerHTML =
    addSubMenuBtn[i].innerHTML +
    '<button class="submenu-btn"><i class="icon icon-chevon-down"></i></button>';
}

// Add is-active on click of Sub Menu Button
var all_items = document.querySelectorAll('.submenu-btn');

Array.from(all_items).forEach((per_item) => {
  per_item.addEventListener('click', function () {
    this.parentNode.classList.toggle('is-active');
  });
});

// Find the viewport height and inject the result pixels into the container
const image = document.getElementById('default-pg-head');
image.style.height = `${window.innerHeight - 115}px`;

// GSAP on Home Page
gsap.registerPlugin(ScrollTrigger);

const numbers = document.querySelectorAll('.counters');

var tl = gsap.timeline({
  scrollTrigger: {
    trigger: numbers,
    start: 'top center',
    markers: false,
    toggleActions: 'restart none none none',
  },
});

gsap.utils.toArray('.counterOne').forEach(function (el) {
  var target = { val: 0 };
  tl.to(target, {
    val: el.getAttribute('data-number'),
    duration: 3,
    onUpdate: function () {
      el.innerText = target.val.toFixed(1);
    },
  });
});

gsap.utils.toArray('.counterTwo').forEach(function (el) {
  var target = { val: 0 };
  tl.to(target, {
    val: el.getAttribute('data-number'),
    duration: 5,
    onUpdate: function () {
      el.innerText = target.val.toFixed(0);
    },
  });
});

gsap.utils.toArray('.counterThree').forEach(function (el) {
  var target = { val: 0 };
  tl.to(target, {
    val: el.getAttribute('data-number'),
    duration: 3,
    onUpdate: function () {
      el.innerText = target.val.toFixed(0);
    },
  });
});

tl.play();
